<template>
  <div class="shop-show-preview">

    <div class="sidebar-model">

      <div class="left-menu">

        <div
          v-for="item in configData.shopShowConfig"
          :key="item.contentId"
          class="menu-item"
          :class="selectedId === item.contentId ? 'selected-menu' : ''"
          @click="selectedMenu(item)"
        >{{ item.title }}</div>

      </div>

      <div class="right-content">

        <div v-for="item in caseList" :key="item.contentId" class="card-item" @click="openDetail(item)">
          <img class="img" :src="item.coverUrl" />
          <div class="text">{{ item.title }}</div>
          <div class="desc">{{ item.depiction }}</div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'ShopShowPreview',
  props: {
    configData: Object
  },
  data() {
    return {
      selectedId: '',
      caseList: []
    }
  },
  computed: {
    themeTabsStyle() {
      return {
        '--selected-theme-after': this.configData.navigationBackgroundColor
      }
    }
  },
  watch: {
    'configData': {
      handler: function() {
        this.init()
      },
      deep: true
    }
  },
  methods: {
    init() {
      if (this.configData.shopShowConfig.length) {
        this.selectedMenu(this.configData.shopShowConfig[0])
        // this.selectedTheme(this.configData.shopShowConfig[0])
      } else {
        this.activeId = ''
        this.seriesTabs = []
        this.caseList = []
      }
    },
    selectedMenu(item) {
      this.selectedId = item.contentId
      this.caseList = item.child
    },

  }
}
</script>

<style lang="scss" scoped>
.shop-show-preview{
  width: 100%;
  background: #fff;
  height: 100%;
  overflow-y: auto;
  position: relative;

  .sidebar-model{
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    background: #f5f5f5;
  }
  .left-menu{
    width:80px;
    height: 100%;
    overflow-y: auto;
  }
  .menu-item{
    font-size: 12px;
    color: #3A3A3A;
    padding: 16px 10px;
    background: #fff;
    margin-top: 10px;
  }
  .menu-item:last-child{
    margin-bottom: 10px;
  }
  .selected-menu{
    background: #409EFF;
    color: #fff;
  }

  .right-content{
    width: calc(100% - 80px);
    padding: 0 10px;
    box-sizing: border-box;
  }
  .card-item{
    margin-top: 10px;
    border-radius: 4px;
    overflow: hidden;
  }
  .card-item:last-child{
    margin-bottom: 10px;
  }
  .card-item .img{
    display: block;
    width: 100%;
    height: auto;
  }
  .card-item .text{
    width: 100%;
    font-size: 14px;
    color: #409EFF;
    background: #fff;
    padding: 6px 12px 0;
    box-sizing: border-box;
  }
  .desc{
    width: 100%;
    box-sizing: border-box;
    padding: 0 12px 6px;
    background: #fff;
    font-size: 12px;
    color: #3A3A3A;
  }
}
</style>
