<!--预览 组件-->
<template>
  <div class="preview-ui">

    <!--预览 组件-->
    <div
        class="component-preview"
        :style="{ 'background': Number(generator.configData.navigationShow) === 1 ? generator.configData.navigationBackgroundColor : '#fff' }"
    >

      <!--导航图标-->
      <div class="header-icon">
        <i class="iconfont icon-xinhao-copy-copy-copy-copy"></i>
        <span class="icon-operator">画眉名片</span>
        <span class="icon-time">13:14</span>
        <span class="header-right">
          <span class="icon-power">95%</span>
            <i class="iconfont icon-dianliang"></i>
          </span>
      </div>

      <!--导航栏-->
      <div
          v-if="Number(generator.configData.navigationShow) === 1"
          class="preview-header"
          :style="{ 'background': generator.configData.navigationBackgroundColor, 'padding-top': Number(generator.configData.navigationShow) ? '24px' : '' }"
      >

        <!--导航标题-->
        <div class="header-heading">
          <span
              :style="{ 'color': generator.configData.navigationFontColor }"
          >{{ generator.configData.navigationTitle }}</span>
          </div>

      </div>

      <!--模板-->
      <div class="preview-view" :style="{'height': Number(generator.configData.navigationShow) == 1 ? 'calc(100% - 64px)' : '100%'}">
        <component :is="generator.activeData.templateComKey" :configData="generator.configData"></component>
      </div>

    </div>

    <p class="preview-ui-title">*此处效果仅供参考，实际以手机端为主。</p>

  </div>
</template>

<script>
import shopShowPreview from '../templateCom/shopShowPreview'
import sidebarPreview from '../templateCom/sidebarPreview'
import cardPreview from '../templateCom/cardPreview'
export default {
  name: 'componentPreview',
  props: {
    generator: Object
  },
  components: {
    'shopShow': shopShowPreview,
    'sidebarModel': sidebarPreview,
    'cardModel': cardPreview
  }
}
</script>

<style lang="scss" scoped>
  .preview-ui{
    width: 375px;
    height: calc(100% - 10px);
    flex: 0 0 375px;
    margin: 0 12px;
  }
  .component-preview{
    width: 100%;
    height: 100%;
    border: 1px solid #e3e5e9;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    position: relative;
    .header-icon{
      padding: 0 12px;
      position: absolute;
      left: 0;
      top: 6px;
      width: 100%;
      display: flex;
      align-items: baseline;
      color: #303133;
      z-index: 1;
      .icon-operator{
        font-size: 14px;
        margin-right: 10px;
      }
      .icon-power{
        font-size: 14px;
        margin-right: 4px;
      }
      i{
        font-size: 14px;
        margin-right: 4px;
      }
      .icon-time{
        margin: 0 auto;
      }
    }
    .preview-header{
      width: 100%;
      height: 64px;
      padding: 5px 10px;
      box-sizing: border-box;
      .header-heading{
        width: 60%;
        height: 35px;
        line-height: 35px;
        font-size: 16px;
        margin: 0 auto;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .preview-view{
      width: 100%;
      height: calc(100% - 64px);
      padding: 0;
      ::v-deep{
        /*!*滚动条初始化*!*/
        ::-webkit-scrollbar {
          width: 4px;
          height: 4px;
          opacity: 0.2;
        }

        ::-webkit-scrollbar-track-piece {
          -webkit-border-radius: 10px;
          opacity: 0.2;
        }

        ::-webkit-scrollbar-thumb:vertical {
          height: 4px;
          background-color: #d3d4d5;
          -webkit-border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:vertical:hover {
          background-color: #a2a3a5;
        }

        ::-webkit-scrollbar-thumb:horizontal {
          width: 4px;
          background-color: #d3d4d5;
          -webkit-border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:horizontal:hover {
          background-color: #a2a3a5;
        }
      }
    }
  }
  .preview-ui-title{
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    color: #606266;
    margin: 40px 0;
  }
</style>
