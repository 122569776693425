<!---->
<template>
  <div class="card-preview">

    <Carousel height="320px" arrow="never">
      <CarouselItem v-for="(item, index) in configData.shopBanner" :key="index">
        <img :src="item" alt="" style="width: 100%;height: 100%">
      </CarouselItem>
    </Carousel>

    <div class="card-content">

      <div class="title">杭州美彦网络科技有限公司 (旗舰店)</div>

      <div class="tag-content">

        <div v-for="(item, index) in configData.shopTag" :key="index" class="tag-item" :class="item.type !== '0' ? `tag-item-${item.type}` : ''">{{ item.value }}</div>

      </div>

      <div class="shop-business-hours">
        <div>营业中</div>
        <div class="shop-business-icon"></div>
        <div>周一至周日 09:00 ~ 18:00</div>
      </div>

      <div class="fn-model">

        <div class="shop-address">萧山区宁围街道民和路479号国泰科技大厦</div>

        <div class="fn-icon">
          <div bindtap="navigation" class="iconfont icon-feiji"></div>
          <div bindtap="makePhone" class="iconfont icon-dianhuatianchong"></div>
        </div>

      </div>

    </div>
    <!--系列-->
    <div class="series-overflow">
      <div class="scroll-view_H" style="width: 100%">
        <div class="series-content">
          <div class="series-content-tabs">
            <div
              v-for="(item, index) in configData.shopShowConfig"
              :key="index"
              class="tabs-item"
              :class="activeSeriesId === item.contentId ? 'active-tabs-item' : ''"
              :style="{ color: activeSeriesId === item.contentId ? '#409EFF' : '' }"
              @click="selectedSeries(item)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div v-for="(item, index) in caseList" :key="index" class="case-items">
        <img
          v-if="item.coverUrl"
          class="case-img"
          :src="item.coverUrl"
        />
        <div class="case-info">
          <div class="case-name">{{ item.title }}</div>
          <div v-if="item.depiction" class="case-des">{{ item.depiction }}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Carousel, CarouselItem } from 'element-ui'

export default {
  name: 'CardPreview',
  components: {
    Carousel, CarouselItem
  },
  props: {
    configData: Object
  },
  data() {
    return {
      activeSeriesId: '',
      caseList: []
    }
  },
  watch: {
    'configData': {
      handler: function() {
        if (this.configData.shopShowConfig) {
          this.selectedSeries(this.configData.shopShowConfig[0])
        }
      },
      deep: true
    }
  },
  methods: {

    // 选择系列
    selectedSeries(item) {
      this.caseList = item.child || []
      this.activeSeriesId = item.contentId
    }
  }
}
</script>

<style lang="scss" scoped>

  .card-preview{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background:#f4f4f4;
    ::v-deep{
      .el-carousel__indicators--horizontal {
        bottom: 56px;
      }
    }

    .card-model{
      width: 100%;
      background-color: #fff;
      padding-bottom: 24px;
    }

    .swiper-item{
      display: block;
      width: 100%;
      height: 320px;
    }
    .card-content{
      width: calc(100% - 24px);
      margin: -50px auto 12px;
      background-color: #fff;
      padding: 0 12px;
      box-sizing: border-box;
      position: relative;
      border-radius: 4px;
      z-index: 99;
    }
    .title{
      font-size: 19px;
      color: #3A3A3A;
      font-weight: 600;
      padding: 12px 0 6px;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .tag-content{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .tag-content{
      border-radius: 4px;
    }
    .tag-item{
      padding: 0 10px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      border-radius: 4px;
      margin-right: 6px;
      border: 1px #fff solid;
    }
    .tag-item-default{
      color: #909399;
      background-color: #f4f4f5;
      border-color: #e9e9eb;
    }
    .tag-item-success{
      color: #67c23a;
      background-color: #f0f9eb;
      border-color: #e1f3d8;
    }
    .tag-item-info{
      color: #409eff;
      background-color: #ecf5ff;
      border-color: #d9ecff;
    }
    .tag-item-warning{
      color: #e6a23c;
      background-color: #fdf6ec;
      border-color: #faecd8;
    }
    .tag-item-danger{
      color: #f56c6c;
      background-color: #fef0f0;
      border-color: #fde2e2;
    }
    .shop-business-hours{
      padding: 12px 0;
      display: flex;
      align-items: center;
      color: #3A3A3A;
      font-size: 14px;
      border-bottom: 1px #f3f3f3 solid;
    }
    .shop-business-icon{
      display: block;
      width: 1;
      height: 10px;
      background-color: #ccc;
      margin: 2px 9px 0;
    }
    .shop-address{
      flex: 1;
      white-space: pre-wrap;
      color: #3A3A3A;
    }

    .fn-model{
      display: flex;
      align-items: baseline;
      font-size: 14px;
      color: #3A3A3A;
      line-height: 18px;
      padding: 6px 0 12px;
    }
    .fn-icon{
      margin-left: auto;
    }
    .xiuxi{
      color: #909399;
    }
    .fn-model .iconfont{
      margin-left: 12px;
    }
    .fn-model .icon-dianhuatianchong{
      font-size: 19px;
    }

    .series-overflow{
      width: 100%;
      position: sticky;
      left: 0;
      top: 0;
      padding: 12px;
      box-sizing: border-box;
      overflow: hidden;
      background: #f4f4f4;
      z-index: 1;
    }
    .series-content{
      width: max-content;
      min-width: 100%;
      overflow-y: hidden;
      overflow-x: auto;
      font-size: 14px;
      color: #475669;
      box-sizing: border-box;
      border-radius: 4px;
    }
    .series-content-tabs{
      width: max-content;
      min-width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-around;
    }
    .active-series{
      color: #409EFF;
      border-bottom: 1px #409eff solid;
    }

    .tabs-overflow{
      width: 100%;
      background: #fff;
      position: sticky;
      left: 0;
      top: 64px;
      overflow-y: hidden;
      overflow-x: scroll;
      z-index: 1;
    }
    .tabs-content{
      width: max-content;
      min-width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-around;
      position: relative;
    }
    .active-tabs-item:after{
      content: '';
      position: absolute;
      left: 50%;
      bottom: 6px;
      width: 80%;
      transform: translateX(-50%);
      background-color: #409eff;
      height: 2px;
    }
    .tabs-item{
      cursor: pointer;
      color: #303133;
      font-weight: 500;
      height: 40px;
      line-height: 40px;
      margin-right: 16px;
      position: relative;
    }

    .case-items {
      width: calc(100% - 24px);
      margin: 0 auto 20px;
      font-size: 12px;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
    }
    .case-items:last-child{
      margin-bottom: 0;
    }
    .case-info{
      width: 100%;
      padding: 6;
      color: #fff;
      box-sizing: border-box;
      background: rgba(0, 0, 0, .1);
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .case-img{
      display: block;
      margin: 0;
      width: 100%;
      height: 140px;
      object-fit: cover;
    }
    .case-name{
      font-size: 16px;
      font-weight: 500;
    }
    .case-des{
      margin-top: 4px;
    }

  }
</style>
